import '@mantine/core/styles.layer.css';
import '@mantine/dates/styles.layer.css';
import 'dayjs/locale/it';

import { RouterProvider } from 'react-router-dom';

import { MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { ModalsProvider } from '@mantine/modals';

import router from '@routes/router';

import theme from './theme';

export default function App() {
  return (
    <MantineProvider theme={theme} forceColorScheme="light">
      <ModalsProvider>
        <DatesProvider settings={{ locale: 'it' }}>
          <RouterProvider router={router} />
        </DatesProvider>
      </ModalsProvider>
    </MantineProvider>
  );
}
