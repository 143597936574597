import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import env from '@config/env';

export function isApiError(
  error: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): error is FetchBaseQueryError & { data: any } {
  return typeof error === 'object' && error != null && 'status' in error;
}

const BASE_URL = env.API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  credentials: 'include',
});

const api = createApi({
  baseQuery,
  tagTypes: [], // Define here tags for cache invalidation
  endpoints: () => ({}),
});

export default api;
