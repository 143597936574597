import { Outlet } from 'react-router-dom';

import { Container, Image, Space, Text, Title } from '@mantine/core';

import logo from '@assets/images/logo.png';

export default function Layout() {
  return (
    <Container pt="xl" size="xl">
      <Image src={logo} maw={200} mx="auto" mb="xl" />
      <Title ta="center">Iscrizioni Festa dello sportivo</Title>
      <Title order={2} ta="center">
        27 e 28 luglio 2024
      </Title>
      <Title order={4} ta="center" fw="normal" mt="md" fs="italic">
        Iscrizioni calcio a 7 CHIUSE, chiusura iscrizioni ping pong 24/07/24
      </Title>
      <Text ta="center" mt="md" size="sm">
        Per informazioni, problemi nella prenotazione o altro scrivere a
        info@mendicantidisogni.com o contattare Giuseppe (347 798 7598) o
        Francesco (348 693 0969)
      </Text>
      <Space h={50} />
      <Outlet />
    </Container>
  );
}
