import { Link } from 'react-router-dom';

import { Button, Group, Stack, Text } from '@mantine/core';

export default function Home() {
  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Stack align="center">
      <Text>Seleziona torneo</Text>
      <Group>
        {/* <Button size="md" component={Link} to="/calcio">
          Calcio a 7
        </Button> */}
        <Button size="md" component={Link} to="/ping-pong">
          Ping pong
        </Button>
      </Group>
    </Stack>
  );
}
