import api from './';

export interface CreatePaypalOrderRequest {
  id: number;
}

export interface CreatePaypalOrderResponse {
  id: string;
}

export interface ConfirmPaypalPaymentRequest {
  id: number;
  body: {
    paypalOrderId: string;
  };
}

export interface ConfirmPaypalPaymentResponse {
  message: string;
}

type BookingType = 'calcio' | 'ping';

export interface CreateBookingRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  teamName?: string;
  type: BookingType;
  birthDate?: Date;
  secondFirstName?: string;
  secondLastName?: string;
  secondBirthDate?: Date;
}

export interface Booking {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  teamName: string;
  total: number;
}

export const {
  useCreateBookingMutation,
  useCreatePaypalOrderMutation,
  useConfirmPaypalPaymentMutation,
  useConfirmBankMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    createBooking: builder.mutation<Booking, CreateBookingRequest>({
      query: (body) => ({
        url: 'booking',
        method: 'POST',
        body,
      }),
    }),
    createPaypalOrder: builder.mutation<
      CreatePaypalOrderResponse,
      CreatePaypalOrderRequest
    >({
      query: ({ id }) => ({
        url: `booking/${id}/paypal-create-order`,
        method: 'POST',
      }),
    }),
    confirmPaypalPayment: builder.mutation<
      ConfirmPaypalPaymentResponse,
      ConfirmPaypalPaymentRequest
    >({
      query: ({ id, body }) => ({
        url: `booking/${id}/paypal-confirm-payment`,
        method: 'POST',
        body,
      }),
    }),
    confirmBank: builder.mutation<ConfirmPaypalPaymentResponse, number>({
      query: (id) => ({
        url: `booking/${id}/confirm-bank`,
        method: 'POST',
      }),
    }),
  }),
});
