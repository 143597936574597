import { Center, Space, Text, Title } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';

export default function Confirmed() {
  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <Center mb="md">
        <IconCircleCheck color="green" size={80} />
      </Center>
      <Title order={2} mb="xl" ta="center">
        La tua prenotazione è stata confermata.
      </Title>
      <Text ta="center">Il capitano riceverà una email di conferma.</Text>
      <Space h={500} />
    </>
  );
}
