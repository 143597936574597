import { createBrowserRouter } from 'react-router-dom';

import Layout from '@components/layout/Layout';

import Confirmed from './Confirmed';
import Home from './Home';
import Ping from './Ping';

export const LOGIN_PATH = '/login';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      // { path: 'calcio', element: <Calcio /> },
      { path: 'ping-pong', element: <Ping /> },
      { path: 'conferma', element: <Confirmed /> },
    ],
  },
]);

export default router;
