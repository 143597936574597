import { MantineThemeOverride, Skeleton } from '@mantine/core';

const theme: MantineThemeOverride = {
  fontFamily: 'Inter',

  colors: {
    brand: [
      '#f3f4f6',
      '#e5e5e5',
      '#c9c9cc',
      '#abacb3',
      '#90929d',
      '#808290',
      '#777a8c',
      '#656879',
      '#5a5d6d',
      '#4c5062',
    ],
  },
  primaryColor: 'brand',

  black: '#2c2f3a',

  components: {
    Skeleton: Skeleton.extend({
      styles: {
        root: {
          width: 'max-content',
        },
      },
    }),
  },
};

export default theme;
