const env = {
  MODE: import.meta.env.MODE,
  API_URL: import.meta.env.VITE_API_URL
    ? import.meta.env.VITE_API_URL
    : `${window.location.protocol}//${window.location.hostname}:5000/api`,
  BASE_URL: import.meta.env.VITE_BASE_URL,
  PAYPAL_CLIENT_ID: import.meta.env.VITE_PAYPAL_CLIENT_ID!,
};

export default env;
