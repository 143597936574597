import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AspectRatio,
  Button,
  Divider,
  Group,
  Space,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { openConfirmModal } from '@mantine/modals';
import { OnApproveData } from '@paypal/paypal-js/types';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { handleSubmitError } from '@utils/forms';

import env from '@config/env';

import {
  Booking,
  useConfirmBankMutation,
  useConfirmPaypalPaymentMutation,
  useCreateBookingMutation,
  useCreatePaypalOrderMutation,
} from '@api/booking.api';

export default function Ping() {
  // ==========================================================================
  // General
  // ==========================================================================
  const navigate = useNavigate();

  // ==========================================================================
  // State
  // ==========================================================================

  const [booking, setBooking] = useState<null | Booking>(null);
  const [error, setError] = useState('');

  // ==========================================================================
  // Api
  // ==========================================================================
  const [createBooking /*{ isLoading }*/] = useCreateBookingMutation();

  const [createPaypalOrder] = useCreatePaypalOrderMutation();

  const [confirmPaypalPayment] = useConfirmPaypalPaymentMutation();

  const [confirmBank, { isLoading: isLoadingConfirmBank }] =
    useConfirmBankMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    // teamName: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    birthDate: null as Date | null,
    // secondFirstName: '',
    // secondLastName: '',
    // secondBirthDate: null as Date | null,
  };

  const form = useForm({
    initialValues,
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================

  const onSubmit = async (values: typeof initialValues) => {
    try {
      const booking = await createBooking({
        ...values,
        birthDate: values.birthDate || undefined,
        type: 'ping',
      }).unwrap();

      setBooking(booking);
    } catch (e) {
      handleSubmitError(e, form);
    }
  };

  const handlePaypalCreateOrder = async () =>
    // _data: CreateOrderData,
    // _actions: CreateOrderActions,
    {
      try {
        setError('');

        return (await createPaypalOrder({ id: booking!.id }).unwrap()).id;
      } catch (e) {
        console.error(e);

        setError('Errore nella creazione del pagamento, riprovare.');
      }

      return '';
    };

  const handlePaypalApprove = async (
    data: OnApproveData,
    // _actions: OnApproveActions,
  ) => {
    try {
      await confirmPaypalPayment({
        id: booking!.id,
        body: { paypalOrderId: data.orderID },
      }).unwrap();

      navigate('/conferma');
    } catch (e) {
      setError('Errore nella creazione del pagamento, riprovare.');
    }
  };

  const handleBankPayment = async () => {
    openConfirmModal({
      size: 'lg',
      title: 'Conferma iscrizione',
      children: (
        <>
          <Text fs="italic">Istruzioni bonifico:</Text>
          <Text>MENDICANTI DI SOGNI ASS.NON RIC.SENZA SCOPO DI LUCRO</Text>
          <Text>POSTE ITALIANE SPA</Text>
          <Text>
            Importo da versare: <strong>€5.00</strong>
          </Text>
          <Text>
            IBAN: <strong>IT10R0760111800001065164079</strong>
          </Text>
          <Text>
            Causale:{' '}
            <strong>iscrizione ping pong festa dello sportivo 2024</strong>
          </Text>
          <br />
          <Text fw="bold">
            Una volta effettuato il pagamento inviare una copia della ricevuta a
            info@mendicantidisogni.com, indicando il nome e cognome. In caso di
            mancato versamento della quota entro una settimana dalla data di
            iscrizione l'iscrizione verrà annullata.
          </Text>
        </>
      ),
      labels: { confirm: 'Conferma iscrizione', cancel: 'Annulla' },
      confirmProps: { loading: isLoadingConfirmBank },
      cancelProps: { loading: isLoadingConfirmBank },
      onConfirm: async () => {
        try {
          await confirmBank(booking!.id).unwrap();

          navigate('/conferma');
        } catch (e) {
          setError('Errore, riprovare.');
        }
      },
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return booking ? (
    <PayPalScriptProvider
      options={{
        clientId: env.PAYPAL_CLIENT_ID,
        currency: 'EUR',
        'disable-funding':
          'card,paylater,bancontact,blik,eps,giropay,ideal,mercadopago,mybank,p24,sepa,sofort,venmo',
      }}
    >
      <Stack my="xl" align="center">
        <Title order={2} mb="xl">
          Conferma pagamento
        </Title>

        <Text fw="bold" size="lg">
          Torneo di ping pong amatoriale
        </Text>
        <Text>
          Nome: <strong>{booking.firstName}</strong>
        </Text>
        <Text>
          Cognome: <strong>{booking.lastName}</strong>
        </Text>
        <Text>
          Telefono: <strong>{booking.phone}</strong>
        </Text>
        <Text>
          Email: <strong>{booking.email}</strong>
        </Text>
        <Text>
          Totale pagamento iscrizione: <strong>€ {booking.total}</strong>
        </Text>
        <Text fs="italic">
          Premi il pulsante Paypal e completa la procedura di pagamento per
          confermare l'iscrizione
        </Text>
        <PayPalButtons
          createOrder={handlePaypalCreateOrder}
          onApprove={handlePaypalApprove}
        />
        <Text c="red">{error}</Text>
        <Text fs="italic">Oppure</Text>
        <Button onClick={handleBankPayment}>Paga con bonifico bancario</Button>
      </Stack>
    </PayPalScriptProvider>
  ) : (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Stack mt="xl" align="center">
        <Title order={2} mb="xl">
          Torneo di ping pong amatoriale
        </Title>
        <Text ta="center" mb="lg">
          <Text c="red" fw="bold">
            IMPORTANTE:
          </Text>{' '}
          L'età minima dei giocatori è di 14 anni, compiuti entro il giorno
          27/07/24.
          <br />
          Dopo il giorno 24/07/24 non sarà più possibile annullare l'iscrizione
          e non verranno effettuati rimborsi.
          <br />
          In caso di annullamento del torneo la quota di iscrizione verrà
          completamente rimborsata.
        </Text>
      </Stack>

      <Space h={50} />

      <Title order={3} mb="lg" ta="center">
        Regolamento
      </Title>
      {/* <Box w="100%" h={900} mb="xl"> */}

      <AspectRatio ratio={4 / 3}>
        <object data="/assets/ping.pdf" type="application/pdf" />
      </AspectRatio>
      <Group justify="center" mt="xl">
        <Button
          component="a"
          href="/assets/ping.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Scarica il regolamento
        </Button>
      </Group>
      {/* </Box> */}

      <Divider my={50} />

      <Text ta="center" fw="bold" mb="md">
        Per iscrizioni scrivere a info@mendicantidisogni.com o contattare
        Giuseppe (347 798 7598)
      </Text>

      {/*  <Text ta="center" fw="bold" mb="md">
        Inserisci tutti i dati nel modulo sottostante per confermare
        l'iscrizione.
      </Text>
      <Text ta="center" mb="md">
        Per completare l'iscrizione è necessario versare la quota di{' '}
        <Text fs="italic" display="inline">
          € 5
        </Text>{' '}
        tramite Paypal o bonifico bancario.
      </Text>

      <Grid maw="50rem" mx="auto" mb="xl">
        <Grid.Col span={{ md: 6 }}>
          <TextInput
            label="Nome"
            required
            {...form.getInputProps('firstName')}
            maxLength={50}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 6 }}>
          <TextInput
            label="Cognome"
            required
            {...form.getInputProps('lastName')}
            maxLength={50}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 6 }}>
          <TextInput
            label="Telefono"
            description="Verrà utilizzato per le comunicazioni ufficiali"
            required
            type="phone"
            {...form.getInputProps('phone')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 6 }}>
          <TextInput
            label="Email"
            description="Verrà utilizzata per le comunicazioni ufficiali"
            required
            type="email"
            {...form.getInputProps('email')}
          />
        </Grid.Col>
        <Grid.Col span={{ md: 6 }}>
          <DateInput
            label="Data di nascita"
            required
            maxDate={new Date(2010, 1, 1)}
            valueFormat="DD/MM/YYYY"
            {...form.getInputProps('birthDate')}
          />
        </Grid.Col>
      </Grid>

      <Group mb="md" justify="center">
        <Checkbox
          name="rules"
          id="rules"
          required
          label="Confermo di aver letto, e accetto il regolamento"
        />
      </Group>
      <Group mb="xl" justify="center">
        <Checkbox
          name="privacy"
          id="privacy"
          required
          label={
            <>
              Confermo di aver letto, e accetto l'
              <Anchor
                href="https://www.iubenda.com/privacy-policy/89100169"
                target="_blank"
                rel="noopener noreferrer"
                size="sm"
              >
                informativa sulla privacy
              </Anchor>
            </>
          }
        />
      </Group>

      <Group justify="center">
        <Button type="submit" loading={isLoading}>
          Conferma iscrizione e prosegui con il pagamento
        </Button>
      </Group>*/}

      <Space h={50} />
    </form>
  );
}
